import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["add_item", "template", "selection", "remove_button"]

  connect() {
    // dont display remove button when only 1 nested form field visible
    if(this.remove_buttonTargets.length == 1) {
      this.remove_buttonTarget.style.display = 'none';
    }
  }

  add_association(event) {
    event.preventDefault()
    var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    this.add_itemTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove_association(event) {
    event.preventDefault()
    let item = event.target.closest(".nested-fields")
    if($('.nested-fields').length > 1) {
      item.querySelector("input[name*='_destroy']").value = 1
      item.style.display = 'none'
      
      // hide remove button when only 1 nested form field visible
      if($('.nested-fields:visible').length == 1) {
        $('.nested-fields:visible .removeb').hide();
      }
    }
  }

  update_checkbox_selection(event) {
    this.selectionTargets.forEach((el, i) => {
      el.checked = false;
    })
    event.target.checked = true;
  }
}
