import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "source" ]

  alert() {
    let headName = this.data.get("currenthead")
    if (this.sourceTarget.checked && headName != '') {
      $('#warning-msg').show();
    } else {
      $('#warning-msg').hide();
    }
  }
  
}