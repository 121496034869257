import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "field" ];

  adoptDate() {
    event.preventDefault();
    const value = this.data.get('value');
    this.fieldTarget.value = value;
  }
}