import { Controller } from "stimulus"

export default class extends Controller {

  connect() {

    let category_anchor = window.location.hash;
    category_anchor && $(category_anchor).collapse('show');
    
    $('.collapse').on('show.bs.collapse', function () {
      $(this).closest('.category-expertises').find('a span').html('<i class="fas fa-caret-down"></i>');
    })

    $('.collapse').on('hide.bs.collapse', function () {
      $(this).closest('.category-expertises').find('a span').html('<i class="fas fa-caret-right"></i>');
    })

    // go to anchor tab when page reloaded
    let hash = window.location.hash;
    hash && $('ul.nav a[href="' + hash + '"]').tab('show');

    $('.nav-tabs a').click(function (e) {
      $(this).tab('show');
      let scrollmem = $('body').scrollTop();
      window.location.hash = this.hash;
      $('html,body').scrollTop(scrollmem);
    });

  }

}
