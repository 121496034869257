import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $('[data-toggle="tooltip"]').tooltip();

    $('.selectpicker').selectpicker();
    let url = document.location.toString();
    let splitHash = url.split("#");
    $("#" + splitHash[1]).tab('show');
  }
}