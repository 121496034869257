import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "source", "select" ];

  connect() {
    this.handleSelectChange()
  }

  handleSelectChange() {
    this.populateSelect(this.sourceTarget.value);
  }

  populateSelect(sourceId) {
    fetch(`/${this.data.get('url')}?id=${sourceId}`, {
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    })
      .then(response => response.json())
      .then(data => {
        this.selectTargets.forEach((el, i) => {
          const selectBox = el;
          selectBox.innerHTML = '';
          data.forEach(item => {
            let opt = document.createElement('option');
            opt.value = item.id;
            opt.innerHTML = eval(this.data.get('optionValue'));
            opt.selected = parseInt($(el).data('level-id')) === item.id;
            selectBox.appendChild(opt);
          });
        })
        $('#expertises_needed_by_function_expertise_has_level_id').selectpicker('refresh');
        $('.expertise-description').html(data[0].expertise.description.body);
      });


  }
}