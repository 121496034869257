import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "source", "dependant" ];

  connect() {
    this.checkBoxChanged();    
  }

  checkBoxChanged() {
    this.displayButton(this.sourceTarget);
  }

  displayButton(target) {
    if (target.checked) {
      $(this.dependantTarget).show();
    } else {
      $(this.dependantTarget).hide();
    }
  }
}