import { Controller } from "stimulus"

export default class extends Controller {
  
  static targets = [ "select", "tomodify" ]

  connect() {
    this.check_selection();
  }

  check_selection() {
    if (this.selectTarget.value == 'empty_' || this.selectTarget.value == '') {
      this.tomodifyTarget.classList.add("invisible");
    } else {
      this.tomodifyTarget.classList.remove("invisible");
    }
  }

}