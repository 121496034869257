import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "source", "dependant" ];

  connect() {
    this.dropdownChanged();    
  }

  dropdownChanged() {
    this.displaySelect(this.sourceTarget);
  }

  displaySelect(target) {
    const value = this.data.get('value');
    if (target.value == value) {
      $(this.dependantTarget).show();
    } else {
      $(this.dependantTarget).hide();
    }
  }
}